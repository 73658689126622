.styledCheckbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  // Box.
  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    border: 1px solid #79bac1;
  }

  // Box hover
  &:hover + label:before {
    background: #79bac1;
  }

  // Box checked
  &:checked + label:before {
    background: #79bac1;
  }

  &:checked + label:before {
    color: white;
  }

  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: '✓';
    position: absolute;
    font-weight: bold;
    left: 2px;
    top: 0px;
    color: white;
    font-size: 1.2rem;
  }
}
