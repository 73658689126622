@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;700&family=Roboto:ital,wght@0,400;0,700;0,900;1,300&display=swap');

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', sans-serif;
}

h1 {
  font-size: 3rem;
  text-align: center;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
}

h5 {
  font-weight: bolder;
  margin-bottom: 5px;
}

p {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 1rem;
}

strong,
b {
  font-weight: bold;
}

i {
  font-style: italic;
}
