.results {
  margin: 20px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.5rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    justify-items: center;
  }

  .result {
    width: 100%;
    max-width: 400px;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    padding: 1.5rem;
    @media (max-width: 768px) {
      padding: 1rem;
    }

    .result-summary {
      margin-bottom: 5px;
      line-height: 1.2;
    }

    .result-image {
    }
  }
}
