.simulator {
  margin-top: 20px;
  position: relative;

  .inner {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    padding: 1.5rem;
    border-radius: 4px;

    @media (max-width: 768px) {
      padding: 1rem 0;
    }
  }

  .main-canvas {
    position: relative;

    .embedButton {
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 10px 30px;
      background-color: white;
      font-size: 1rem;
      font-weight: 500;
      letter-spacing: 0.5px;
      z-index: 3;
      border: 1px solid #5e656c;
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        background-color: #79bac1;
        color: white;
      }
      img {
        height: 10px;
      }
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.6);

      .overlay-box {
        padding: 15px 40px;
        background-color: white;
        text-align: center;
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

        &.embedded {
          padding: 0;
        }

        .controls {
          margin: 15px 0;

          .control {
            margin-bottom: 15px;
          }
        }

        .simulationEnded {
          p {
            font-size: 0.9rem;
            margin: 0;
          }
          padding-bottom: 5px;
          border-bottom: 1px solid lighten(#5e656c, 50%);
        }
      }

      .play {
        color: white;
        margin-top: 10px;
        img {
          margin-left: 5px;
          width: 12px;
        }
      }

      .embeddedControlButton {
        background-color: white;
        color: #5e656c;
        font-size: 1.2rem;
        padding: 15px 40px;

        img {
          width: 20px;
          vertical-align: middle;
          margin-left: 10px;
        }
      }
    }

    .speed-controls {
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 3;

      .control {
        .label {
          display: none;
        }
      }
    }
  }

  canvas {
    border: 1px solid black;
    margin: 0 auto;
    position: relative;
    display: block;
  }
}
