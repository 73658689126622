.embedWrap {
  max-width: 800px;
  margin: 0 auto;

  .simulator {
    margin: 0;
    .inner {
      box-shadow: none;
    }
  }
}
