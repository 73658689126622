@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;700&family=Roboto:wght@400;700&display=swap');

html {
  font-size: 16px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
}

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-bottom: 80px;
}

* {
  box-sizing: border-box;
}

.header {
  text-align: center;

  h1 {
    line-height: 1.2;
    margin: 20px 0 20px 0;

    @media (max-width: 800px) {
      font-size: 1.6rem;
    }
  }

  h2 {
    margin-bottom: 2rem;
  }
}

.container {
  width: 800px;
  margin: 0 auto;
  max-width: 100%;

  @media (max-width: 800px) {
    padding: 0 15px;
    &.noPadMobile  {
      padding: 0;
    }
  }
}

a {
  color: #79bac1;
}

.shareButton {
  cursor: pointer;
  button {
    background-color: #1877f2;
    color: white;
    border: none;
    outline: none;
    font-size: 1.1rem;
    padding: 5px 20px;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.4);

    img {
      width: 18px;
    }
  }
}

button.styledButton {
  color: white;
  padding: 5px 15px;
  outline: none;
  border: none;
  font-size: 1rem;
  font-family: 'Roboto';
  cursor: pointer;
  border: 1px solid transparent;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.4);

  &.primary {
    background-color: #79bac1;
  }

  &.secondary {
    background-color: #5e656c;
  }

  &.bigger {
    padding: 8px 32px;
  }

  &:hover {
    border: 1px solid black;
  }
}

canvas,
img,
svg {
  max-width: 100%;
}

.healthy {
  color: var(--healthy-color);
}

.infected {
  color: var(--infected-color);
}

.dead {
  color: var(--dead-color);
}

.recovered {
  color: var(--recovered-color);
}

.isolated {
  color: var(--healthy-color);
}
