.controls {
  &.denser {
    .control .options button {
      padding: 3px 10px;
      font-size: 0.9rem;
    }

    .control .label {
      font-size: 0.9rem;
    }
  }

  .control {
    text-align: center;

    @media (max-width: 768px) {
      margin: 5px;
    }

    .label {
      margin-bottom: 10px;
      font-size: 1.2rem;

      @media (max-width: 768px) {
        font-size: 1rem;
        margin-bottom: 5px;
      }
    }

    .options {
      display: inline-flex;
      justify-content: center;
      border: 1px solid #6c7176;

      button {
        padding: 5px 15px;
        display: inline-block;
        border: 1px solid white;
        font-size: 1rem;
        cursor: pointer;
        outline: none;
        transition: all 0.2s ease;

        @media (max-width: 768px) {
          padding: 3px 12px;
          font-size: 0.9rem;
        }

        &:hover {
          background-color: #5e656c;
          color: white;
        }

        &.active {
          background-color: #5e656c;
          color: white;
        }
      }
    }
  }
}
