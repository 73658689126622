.ReactModal__Overlay {
  z-index: 22;
  display: flex;
  justify-content: center;
  align-items: center;

  .ReactModal__Content {
    width: 600px;
    position: relative;
    padding: 80px 60px !important;

    .closeButton {
      position: absolute;
      top: 0px;
      right: 0px;
      color: white;
      padding: 10px;
      z-index: 20;
      cursor: pointer;

      button {
        background-color: transparent;
        outline: none;
        border: none;
        cursor: pointer;
      }

      img {
        width: 30px;
      }
    }

    .form {
      margin-top: 30px;

      .currentSettings {
        color: #5e656c;
        font-weight: 300;
        font-size: 0.9rem;
        margin-top: 5px;
        line-height: 1.3;
      }

      .selectSettings {
        margin-top: 10px;

        .form-group {
          margin-bottom: 5px;
        }
      }
    }

    .link {
      margin: 30px 0;

      label {
        display: block;
        margin-bottom: 5px;
      }

      input {
        width: 100%;
        border: 1px solid #5e656c;
        padding: 0.5rem;
        font-size: 0.9rem;
      }
    }
    textarea {
      width: 100%;
      resize: none;
      border: 1px solid #5e656c;
      padding: 0.5rem;
      font-size: 0.9rem;
      height: 100px;
      margin-top: 5px;
    }
  }
}
