.imagePreview {
  display: flex;
  margin-bottom: 30px;

  figure {
    display: block;
    flex-shrink: 1;
    padding: 0 25px;

    img {
      max-width: 100%;
    }

    figcaption {
      font-style: italic;
      color: #5e656c;
      margin-top: 3px;
      font-size: 0.95rem;
    }
  }
}

.textContent {
  margin: 0 auto;
}

.textShare {
  display: flex;

  .share {
    margin-left: 20px;
    flex-shrink: 0;
  }
}

.moreContent > h3 {
  margin-top: 20px;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  display: inline-block;
  margin: 0 5px;

  &.healthy {
    background-color: var(--healthy-color);
  }

  &.isolated {
    background-color: var(--healthy-color);
    border: 1px solid black;
  }

  &.infected {
    background-color: var(--infected-color);
  }

  &.dead {
    background-color: var(--dead-color);
  }

  &.recovered {
    background-color: var(--recovered-color);
  }
}
