.credits {
  margin-top: 40px;

  ul {
    border-top: 1px solid lighten(#5e656c, 40%);
    padding-top: 40px;
    list-style-type: none;

    li {
      margin-bottom: 10px;

      .icons {
        display: inline-block;
        color: #5e656c;
        margin-left: 5px;

        img {
          width: 18px;
          vertical-align: middle;
          margin: 0 5px;
        }
      }
    }
  }
}
