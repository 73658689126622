.summary {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: flex-start;

  @media (max-width: 768px) {
    padding: 0 0.8rem;
    align-items: flex-end;
  }

  .counters {
    @media (max-width: 768px) {
      padding-right: 8px;
    }

    table {
      width: 150px;

      @media (max-width: 768px) {
        width: 120px;
      }

      tr {
        color: attr(data-color);
        background-color: attr(data-color);
      }

      td {
        padding: 5px 0;
        border-bottom: 1px solid lighten(#5e656c, 50%);

        @media (max-width: 768px) {
          font-size: 0.9rem;
        }

        &:last-of-type {
          width: 30px;

          @media (max-width: 768px) {
            width: 20px;
          }
        }

        .dot {
          display: inline-block;
          width: 10px;
          height: 10px;
          border-radius: 100%;
          margin-right: 5px;

          @media (max-width: 768px) {
            width: 6px;
            height: 6px;
            margin-right: 3px;
          }
        }

        span[id] {
          font-weight: 900;
        }
      }
    }
  }
}
